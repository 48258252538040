import '@telegram-apps/telegram-ui/dist/styles.css';
import '../index.css';
import WebApp from '@twa-dev/sdk'
import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    WebApp.expand();
    //WebApp.setBackgroundColor("#00000000");
  }, [])
  return (
      <Outlet />
  )
}

export default App