import { createBrowserRouter } from "react-router-dom";
import { AppRoot } from "@telegram-apps/telegram-ui";
import App from "./App";
import { HomePage } from "./pages/home-page";
import { TreePage } from "./pages/tree-page";
import { CssVarsProvider } from "@mui/joy/styles";
import { CssBaseline } from "@mui/joy";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <CssVarsProvider>
        <AppRoot style={{ backgroundColor: 'transparent' }} appearance="light" className="root">
          <App />
        </AppRoot>
        <CssBaseline />

        {/* The rest of your application */}
      </CssVarsProvider>),
    children: [
      {
        path: "/home",
        element: <HomePage />,
      },
      {
        path: "/tree",
        element: <TreePage />,
      },
    ]
  }

]);