import React from "react";
import { Image } from '@telegram-apps/telegram-ui';
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import TreeView, { flattenTree } from "react-accessible-treeview";
import "./styles.css";

const folder = {
  name: "",
  children: [
    {
      name: "Вася",
      children: [
        { 
          name: "Abrakadabra",
          metadata: {
            level: 2
          } 
        }, 
        { name: "Andrew",
          metadata: {
            level: 2
          } }],
      metadata: {
        level: 0
      }
    },
    {
      name: "Петя",
      children: [
        {
          name: "Женя",
          children: [{ name: "Анна 1",
            metadata: {
              level: 1
            } },{ name: "Анна 2",
              metadata: {
                level: 1
              } },{ name: "Анна 3",
                metadata: {
                  level: 1
                } }],
          metadata: {
            level: 3
          }
        },
        {
          name: "Виталий",
          children: [{ name: "Андрей" }],
          metadata: {
            level: 4
          }
        },
        {
          name: "Антон",
          children: [{ name: "Семен" }],
        },
        { name: "Слава", children: [{ name: "Марина" }] },
      ],
      metadata: {
        level: 4
      }
    },
    {
      name: "Иван",
      children: [
        {
          name: "react-accessible-treeview",
          children: [{ name: "index.js" }],
        },
        { name: "react", children: [{ name: "index.js" }] },
      ],
      metadata: {
        level: 3
      }
    }
  ],
};

const data = flattenTree(folder);

function DirectoryTreeView() {
  return (
    <div>
      <div className="directory">
        <TreeView
          data={data}
          aria-label="directory tree"
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            getNodeProps,
            level,
          }) => (
            <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1), display:'flex', gap:5, margin:2, fontSize:14, fontFamily:'var(--joy-fontFamily-body, "Inter", var(--joy-fontFamily-fallback, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"))' }}>
              {isBranch  && <FolderIcon isOpen={isExpanded} />}
              <UserIcon level={element.metadata?.level as number | undefined}/>
              {element.name}
            </div>
          )}
        />
      </div>
    </div>
  );
}

type FolderIconPros = { isOpen: boolean; }
const FolderIcon = ({ isOpen }: FolderIconPros) =>
  isOpen ? (
    <IoIosArrowDown className="icon" />
  ) : (
    <IoIosArrowForward className="icon" />
  );

/*function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}*/

type UserIconPros = { level?: number; }
const UserIcon = ({level}: UserIconPros) => {
  //const val = getRandomInt(4);
  switch (level) {
    case 0:
      return <Image size={20} src='https://axiomechain.org/img/boss.7c2cfe9e.png' />;
    case 1:
      return <Image size={20} src='https://axiomechain.org/img/guru.7ddfec79.png' />;
    case 2:
      return <Image size={20} src='https://axiomechain.org/img/leader.33633771.png' />;
    case 3:
      return <Image size={20} src='https://axiomechain.org/img/pro.4247854c.png' />;
    case 4:
      return <Image size={20} src='https://axiomechain.org/img/top.07f7d430.png' />;
    default:
      return null;
  }
}

export default DirectoryTreeView;