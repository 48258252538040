import '../../index.css';
import { Banner, Image, Section, TabsList } from '@telegram-apps/telegram-ui';
import { TabsItem } from '@telegram-apps/telegram-ui/dist/components/Navigation/TabsList/components/TabsItem/TabsItem';
import React from 'react';
import DirectoryTreeView from '../../components/tree-chain';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';

function RowCard() {
    return (
      <Card orientation="horizontal" variant="outlined" sx={{ height:45, width:160 }}>
        <CardContent>
          <Typography fontSize={14} textColor="primary">
            Личный стейкинг
          </Typography>
          <Typography level="body-xs">30 000 AXM</Typography>
        </CardContent>
        <CardOverflow
          variant="soft"
          color="primary"
          sx={{
            px: 0.2,
            writingMode: 'vertical-rl',
            justifyContent: 'center',
            fontSize: 'xs',
            fontWeight: 'xl',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            borderLeft: '1px solid',
            borderColor: 'divider',
          }}
        >
          MEGA
        </CardOverflow>
      </Card>
    );
  }

 function RowCard2() {
    return (
      <Card orientation="horizontal" variant="outlined" sx={{ height:45, width:160 }}>
        <CardContent>
          <Typography fontSize={14} textColor="primary">
            Командный стейкинг
          </Typography>
          <Typography level="body-xs">300 000 AXM</Typography>
        </CardContent>
        <CardOverflow
          variant="soft"
          sx={{
            backgroundColor: 'yellow',
            px: 0.2,
            writingMode: 'vertical-rl',
            justifyContent: 'center',
            fontSize: 'xs',
            fontWeight: 'xl',
            letterSpacing: '1px',
            textTransform: 'uppercase',
            borderLeft: '1px solid',
            borderColor: 'divider',
          }}
        >
          TOP
        </CardOverflow>
      </Card>
    );
  }

export const TreePage = () => {
    const [selectedTab, setSelectedTab] = React.useState('1');
    return (
        <>
            <Section>
                <Banner
                    before={<Image size={48} src='https://axiomechain.org/img/mega.1a519701.png' />}
                    header="AtmaPrem"
                    subheader="MEGA"
                >
                </Banner>
            </Section>
            <Section>
            <TabsList>
                <TabsItem
                    id='1'
                    onClick={() => setSelectedTab('1')}
                    selected={selectedTab === '1'}
                >
                    Структура
                </TabsItem>
                <TabsItem
                    id='1'
                    onClick={() => setSelectedTab('2')}
                    selected={selectedTab === '2'}
                >
                    KPI
                </TabsItem>
            </TabsList>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '5px',
                gap: '10px'
            }}>
            <RowCard/>
            <RowCard2/>
            </div>
            <DirectoryTreeView />
            </Section>
        </>
    )
}