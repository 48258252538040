// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.directory {
    background: transparent;
    font-family: monospace;
    font-size: 16px;
    color: rgb(0, 0, 0);
    -webkit-user-select: none;
            user-select: none;
    padding: 20px;
    border-radius: 0.4em;
  }
  
  .directory .tree,
  .directory .tree-node,
  .directory .tree-node-group {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .directory .tree-branch-wrapper,
  .directory .tree-node__leaf {
    outline: none;
    outline: none;
  }
  
  .directory .tree-node {
    cursor: pointer;
  }
  
  .directory .tree-node:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  
  .directory .tree .tree-node--focused {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .directory .tree .tree-node--selected {
    background: rgb(97, 154, 219, 0.2);
  }
  
  .directory .tree-node__branch {
    display: block;
  }
  
  .directory .icon {
    vertical-align: middle;
    padding-right: 5px;
  }`, "",{"version":3,"sources":["webpack://./src/components/styles.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,eAAe;IACf,mBAAmB;IACnB,yBAAiB;YAAjB,iBAAiB;IACjB,aAAa;IACb,oBAAoB;EACtB;;EAEA;;;IAGE,gBAAgB;IAChB,SAAS;IACT,UAAU;EACZ;;EAEA;;IAEE,aAAa;IACb,aAAa;EACf;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,oCAAoC;EACtC;;EAEA;IACE,oCAAoC;EACtC;;EAEA;IACE,kCAAkC;EACpC;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;EACpB","sourcesContent":[".directory {\n    background: transparent;\n    font-family: monospace;\n    font-size: 16px;\n    color: rgb(0, 0, 0);\n    user-select: none;\n    padding: 20px;\n    border-radius: 0.4em;\n  }\n  \n  .directory .tree,\n  .directory .tree-node,\n  .directory .tree-node-group {\n    list-style: none;\n    margin: 0;\n    padding: 0;\n  }\n  \n  .directory .tree-branch-wrapper,\n  .directory .tree-node__leaf {\n    outline: none;\n    outline: none;\n  }\n  \n  .directory .tree-node {\n    cursor: pointer;\n  }\n  \n  .directory .tree-node:hover {\n    background: rgba(255, 255, 255, 0.1);\n  }\n  \n  .directory .tree .tree-node--focused {\n    background: rgba(255, 255, 255, 0.2);\n  }\n  \n  .directory .tree .tree-node--selected {\n    background: rgb(97, 154, 219, 0.2);\n  }\n  \n  .directory .tree-node__branch {\n    display: block;\n  }\n  \n  .directory .icon {\n    vertical-align: middle;\n    padding-right: 5px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
