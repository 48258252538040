import '../../index.css';
import { Button, FixedLayout, LargeTitle, Modal, Placeholder } from "@telegram-apps/telegram-ui";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import React from "react";
import { useNavigate } from "react-router-dom";

export const HomePage = () => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const navigate = useNavigate();
    return (
        <>
            <FixedLayout vertical="top" style={{
                padding: 26
            }}>
                <LargeTitle
                    style={{ textAlign: "center" }}
                    caps
                    weight="3"
                >
                    Welcome, user!
                </LargeTitle><br /><br /><br /><br />
                <Button size="l" stretched onClick={() => setModalOpen(true)}>
                    Connect wallet
                </Button>
            </FixedLayout>
            <Modal
                header={<ModalHeader>Подключить кошелек</ModalHeader>}
                open={modalOpen}
                onOpenChange={(open) => setModalOpen(open)}
            >
                <Placeholder
                    description='Нажмите кнопку "Подключить" ниже'
                    header="Шаг 1"
                >
                    <img
                        alt="Telegram sticker"
                        className="blt0jZBzpxuR4oDhJc8s"
                        src="https://axiomechain.org/img/logo.5faf862d.png"
                        width={178}
                    />
                </Placeholder>
                <Placeholder
                    description='Вас автоматически перенаправит в приложение Axiome Wallet, где откроется окно авторизации. Нажмите «Подтвердить».'
                    header="Шаг 2"
                />
                <Placeholder
                    description='Вернитесь в telegram. Ваш кошелек будет авторизован.'
                    header="Шаг 3"
                />
                <div style={{
                    margin: 16,
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                <Button style={{
                    margin: 16,
                    textAlign: 'center',
                    width: 360,
                    display: 'flex',
                    justifyContent: 'center'
                }}
                    size="m" onClick={() => navigate('/tree')}>
                    Подключить
                </Button>

                </div>
            </Modal>
        </>
    );
}